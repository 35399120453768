var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal", appear: "" } }, [
    _c(
      "div",
      {
        staticClass: "modal modal-overlay",
        on: {
          click: function($event) {
            if ($event.target !== $event.currentTarget) {
              return null
            }
            return _vm.$emit("close")
          }
        }
      },
      [
        _c("div", { staticClass: "modal-window" }, [
          _c(
            "div",
            { staticClass: "modal-content" },
            [
              _vm._t("header", [
                _c("div", { attrs: { id: "modal-header" } }, [
                  _c(
                    "a",
                    {
                      attrs: { id: "close-button", href: "#" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("close")
                        }
                      }
                    },
                    [_c("i", { staticClass: "fas fa-times-circle" })]
                  )
                ])
              ]),
              _c("div", { attrs: { id: "map" } }, [_c("Map")], 1)
            ],
            2
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }