<template lang="pug">
  transition(name="modal" appear="")
    .modal.modal-overlay(@click.self="$emit('close')")
      .modal-window
        //-モーダル画面の内容
        .modal-content
          slot(name="header")
            #modal-header
              a#close-button(href="#" @click="$emit('close')")
                i.fas.fa-times-circle
          #map
            Map
</template>

<script>
import Map from '@/components/Map.vue'

export default {
  // データオブジェクト
  props:{
  },
  data() {
    return {
    }
  },
  // メソッド
  methods: {
    window:onload = function() {
    }
  },
  // コンポーネント
  components: {
    Map
  }
}
</script>

<style lang="stylus" scoped>
.modal {
  &.modal-overlay {
    display flex
    align-items center
    justify-content center
    position fixed
    z-index 30
    top 0
    left 0
    width 100%
    height 100%
    background rgba(0, 0, 0, 0.5)
  }

  &-window {
    background #fff
    border-radius 4px
    overflow hidden
    width 100%
    height 85vh
  }

  &-content {
    padding 10px 20px
    background-color #080d1a
    width 100%
    height 85vh
  }

  &-footer {
    background #ccc
    padding 10px
    text-align right
  }
}

// オーバーレイのトランジション
.modal-enter-active, .modal-leave-active {
  transition opacity 0.4s
  // オーバーレイに包含されているモーダルウィンドウのトランジション
  .modal-window {
    transition opacity 0.4s, transform 0.4s
  }
}

// ディレイを付けるとモーダルウィンドウが消えた後にオーバーレイが消える
.modal-leave-active {
  transition opacity 0.6s ease 0.4s
}

.modal-enter, .modal-leave-to {
  opacity 0

  .modal-window {
    opacity 0
    transform translateY(-20px)
  }
}

.modal-content
  border 2px solid #3A5263

#modal-header
  margin 0 0 0 auto//右寄せ

// 閉じるボタン
i.fas.fa-times-circle
  color #fff
  font-size 2rem
  margin 1rem 0

#map
  width 100%
  height 100%

// 大デバイス（デスクトップ, 1170px 以上）
@media (min-width 1170px)
  .modal-window
    width 100%
  .modal-content
    margin 0 auto //中央寄せ

</style>